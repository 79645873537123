<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  height: 100%;
  box-sizing: border-box;
  font-family: "HelveticaNeue-Medium", "HelveticaNeue", "PingFangSC-Regular",
    "PingFang SC", "Microsoft YaHei", sans-serif !important;
}
</style>
